import React, { useState } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css'; 
import config from '../config';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function AddWorkflow() {
    const [useGemini, setUseGemini] = useState(false); // Toggle between manual and Gemini
    const [workflow, setWorkflow] = useState({
        name: '',
        description: '',
        type: 'primitive',
        tasks: []
    });
        // Instruction for Gemini API
    const geminiInstruction = `
        Instructions: [The users will describe a specific workflow, and your task is to generate a workflow with tasks and fields according to the user's description. The response should be in JSON format only, like this example:
        {
            "name": "Academic Vacancy 2024",
            "description": "This is Academic Vacancy Workflow",
            "type": "primitive",
            "tasks": [
                {
                    "name": "Filling The Academic Vacancy Form",
                    "description": "This task includes filling all fields in the Academic Vacancy form",
                    "status": "Done",
                    "task_fields": [
                        {
                            "fieldTypeID": "text",
                            "fieldName": "Name",
                            "required": true,
                            "index": 0
                        },
                        {
                            "fieldTypeID": "number",
                            "fieldName": "Age",
                            "required": true,
                            "index": 1
                        }
                    ]
                },
                ...
            ]
        }
        Valid fieldTypeIDs are: text, number, information]
        The desired workflow is:
        `;
    

    const [geminiDescription, setGeminiDescription] = useState(''); 
    const [responseText, setResponseText] = useState('');


    const handleGeminiDescriptionChange = (e) => {
        setGeminiDescription(e.target.value);
    };


    const handleGenerateContent = async () => {
        const genAI = new GoogleGenerativeAI('AIzaSyAv1vNNiB-8UUxfFleo1j3v6NedSF7L_yY');
        const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

        try {
            const prompt = `${geminiInstruction}\n${geminiDescription}`;
            const result = await model.generateContent(prompt);

            let workflowData = result.response.text();

            workflowData = workflowData.replace(/```json|```/g, '').trim();

            const parsedWorkflow = JSON.parse(workflowData);

            setWorkflow(parsedWorkflow); // Populate formData with generated data

            //setResponseText(JSON.stringify(parsedWorkflow, null, 2));

            console.log("Generated Workflow:", parsedWorkflow);
        } catch (error) {
            console.error('Error generating content:', error);
            alert('Failed to generate content: ' + (error.message || "Unknown error"));
        }
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setWorkflow(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleTaskChange = (e, index) => {
        const { name, value } = e.target;
        setWorkflow(prev => {
            const updatedTasks = [...prev.tasks];
            updatedTasks[index][name] = value;
            return {
                ...prev,
                tasks: updatedTasks
            };
        });
    };

    const handleTaskFieldChange = (e, taskIndex, fieldIndex) => {
        const { name, value } = e.target;
        setWorkflow(prev => {
            const updatedTasks = [...prev.tasks];
            updatedTasks[taskIndex].task_fields[fieldIndex][name] = value;
            return {
                ...prev,
                tasks: updatedTasks
            };
        });
    };

    const addTask = () => {
        setWorkflow(prev => ({
            ...prev,
            tasks: [
                ...prev.tasks,
                {
                    name: '',
                    description: '',
                    status: 'Active',
                    task_fields: []
                }
            ]
        }));
    };

    const addTaskField = (taskIndex) => {
        setWorkflow(prev => {
            const updatedTasks = [...prev.tasks];
            updatedTasks[taskIndex].task_fields.push({
                fieldName: '',
                fieldTypeID: 'text',
                required: false,
                index: updatedTasks[taskIndex].task_fields.length
            });
            return {
                ...prev,
                tasks: updatedTasks
            };
        });
    };

    // Delete a specific task
    const deleteTask = (taskIndex) => {
        setWorkflow(prev => ({
            ...prev,
            tasks: prev.tasks.filter((_, index) => index !== taskIndex)
        }));
    };

    // Delete a specific field from a task
    const deleteTaskField = (taskIndex, fieldIndex) => {
        setWorkflow(prev => {
            const updatedTasks = [...prev.tasks];
            updatedTasks[taskIndex].task_fields = updatedTasks[taskIndex].task_fields.filter((_, index) => index !== fieldIndex);
            return {
                ...prev,
                tasks: updatedTasks
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
            alert("You are not authenticated. Please login.");
            return;
        }

        try {
            const response = await axios.post(
                `${config.apiUrl}/workflows/`,
                workflow,
                {
                    headers: {
                        'Authorization': `Token ${authToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            alert('Workflow added successfully');
            // Reset form data
            setWorkflow({
                name: '',
                description: '',
                type: 'primitive',
                tasks: []
            });
            setResponseText('');
            setGeminiDescription('');
            setUseGemini(false);
        } catch (error) {
            const errorMessage = error.response?.data?.message || "An unknown error occurred. Please check your input and try again.";
            console.error('Failed to add workflow:', errorMessage);
            alert('Failed to add workflow: ' + errorMessage);
        }
    };

    return (
        <div>
            <h2>Add New Workflow</h2>
            <div>
                <label>
                    <input
                        type="checkbox"
                        checked={useGemini}
                        onChange={() => setUseGemini(!useGemini)}
                    />
                    Use Gemini Description
                </label>
            </div>

            {useGemini && (
                // set fixed width for the textarea
                <div style={{ marginBottom: '10px' }}>
                    <label
                        style={{ display: 'block', marginBottom: '10px' }}
                    >Gemini Workflow Description:</label>
                    <textarea 
                        value={geminiDescription}
                        onChange={handleGeminiDescriptionChange}
                        placeholder="Enter description for Gemini API"
                        required
                        style={{ width: '60%', height: '100px' }}
                    />
                    <button
                        type="button" 
                        // align the button to the center


                        style={{ marginTop: '15px', width: '500px', display: 'block', margin: 'auto' }}

                        onClick={handleGenerateContent}>Generate Content
                    </button>
                    {responseText && (
                        <div style={{ marginTop: '20px' }}>
                            <p>Generated Response:</p>
                            <pre style={{ backgroundColor: '#f4f4f4', padding: '10px' }}>{responseText}</pre>
                        </div>
                    )}
                </div>
            )}

            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginBottom: '10px' }}>
                    <label style={{ marginRight: '10px' }}>Workflow Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={workflow.name || ''}
                        onChange={handleFormChange}
                        required
                        style={{ width: '100%' }}
                    />
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <label style={{ marginRight: '10px' }}>Description:</label>
                    <ReactQuill
                        value={workflow.description || ''}
                        onChange={(value) => setWorkflow(prev => ({ ...prev, description: value }))}
                        theme="snow"
                    />
                </div>
                {(workflow.tasks || []).map((task, taskIndex) => (
                    <div key={taskIndex} style={{ border: '1px solid #ccc', padding: '15px', marginBottom: '20px', position: 'relative' }}>
                        {/* X icon for deleting the task */}
                        <FontAwesomeIcon 
                            icon={faTimes} 
                            onClick={() => deleteTask(taskIndex)} 
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                cursor: 'pointer',
                                color: 'red'
                            }} 
                        />
                        <h4>Task {taskIndex + 1}</h4>
                        <div style={{ marginBottom: '10px' }}>
                            <label>Task Name:</label>
                            <input
                                type="text"
                                name="name"
                                value={task.name || ''}
                                onChange={(e) => handleTaskChange(e, taskIndex)}
                                required
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <label>Task Description:</label>
                            <textarea
                                name="description"
                                value={task.description || ''}
                                onChange={(e) => handleTaskChange(e, taskIndex)}
                                required
                                style={{ width: '100%', height: '80px' }}
                            />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <label>Status:</label>
                            <select
                                name="status"
                                value={task.status || 'Active'}
                                onChange={(e) => handleTaskChange(e, taskIndex)}
                                style={{ width: '100%' }}
                            >
                                <option value="Active">Active</option>
                                <option value="Pending">Pending</option>
                                <option value="Done">Done</option>
                            </select>
                        </div>
                        <h5>Task Fields</h5>
                        {(task.task_fields || []).map((field, fieldIndex) => (
                            <div key={fieldIndex} style={{ marginBottom: '10px', padding: '10px', backgroundColor: '#f9f9f9', position: 'relative' }}>
                                {/* X icon for deleting the field */}
                                <FontAwesomeIcon 
                                    icon={faTimes} 
                                    onClick={() => deleteTaskField(taskIndex, fieldIndex)} 
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        cursor: 'pointer',
                                        color: 'red'
                                    }} 
                                />
                                <div style={{ marginBottom: '5px' }}>
                                    <label>Field Name:</label>
                                    <input
                                        type="text"
                                        name="fieldName"
                                        value={field.fieldName || ''}
                                        onChange={(e) => handleTaskFieldChange(e, taskIndex, fieldIndex)}
                                        required
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div style={{ marginBottom: '5px' }}>
                                    <label>Field Type:</label>
                                    <select
                                        name="fieldTypeID"
                                        value={field.fieldTypeID || 'text'}
                                        onChange={(e) => handleTaskFieldChange(e, taskIndex, fieldIndex)}
                                        style={{ width: '100%' }}
                                    >
                                        <option value="text">Text</option>
                                        <option value="number">Number</option>
                                        <option value="information">Information</option>
                                    </select>
                                </div>
                                <div style={{ marginBottom: '5px' }}>
                                    <label>Required:</label>
                                    <input
                                        type="checkbox"
                                        name="required"
                                        checked={field.required || false}
                                        onChange={(e) => handleTaskFieldChange(
                                            { target: { name: 'required', value: e.target.checked } },
                                            taskIndex,
                                            fieldIndex
                                        )}
                                    />
                                </div>
                            </div>
                        ))}
                        <button type="button" onClick={() => addTaskField(taskIndex)}>Add Field</button>
                    </div>
                ))}
                <button type="button" onClick={addTask}>Add Task</button>
                <br />
                <button type="submit" style={{ marginTop: '20px' }}>Add Workflow</button>
            </form>
        </div>
    );
}

export default AddWorkflow;
