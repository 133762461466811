import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Login.css'; // Ensure your CSS file is updated accordingly.
import config from '../config';
import { UserContext } from './UserContext';
import { initializeGoogleSignIn, handleCredentialResponse } from '../services/googleAuthService';

function Register() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('male');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const { setUser } = useContext(UserContext);

    const navigate = useNavigate();

    const handleRegister = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${config.apiUrl}/auth/register/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, email, password1: password, password2: password, first_name: firstName, last_name: lastName, gender, date_of_birth: dateOfBirth })
            });
            if (!response.ok) throw new Error('Registration failed');
            alert('Registration successful');
            navigate('/login');
        } catch (error) {
            console.error('Registration error:', error);
            alert('Failed to register');
        }
    };

    useEffect(() => {
        const callback = async (response) => {
            const result = await handleCredentialResponse(response.credential, config.apiUrl);
            if (result.success) {
                localStorage.setItem('authToken', result.key);
                setUser({ username: result.username });
                if (result.created) {
                    navigate('/profile');
                }
                else {
                    navigate('/');
                }
            } else {
                alert('Google Sign-In failed: ' + result.message);
            }
        };

        initializeGoogleSignIn(config.googleClientId, callback);
    }, [navigate]);

    return (
        <div className="auth-container">
            <form onSubmit={handleRegister} className="auth-form">
                <h2>Sign Up</h2>
                <input type="text" placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} />
                <input type="text" placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} />
                <input type="text" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} />
                <input type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                <div className="row">
                    <input type="date" className="date-input" value={dateOfBirth} onChange={e => setDateOfBirth(e.target.value)} />
                    <div className="gender-selection">
                        <label>
                            <input type="radio" value="male" checked={gender === 'male'} onChange={() => setGender('male')} />
                            Male
                        </label>
                        <label>
                            <input type="radio" value="female" checked={gender === 'female'} onChange={() => setGender('female')} />
                            Female
                        </label>
                    </div>
                </div>
                <button type="submit">Sign Up</button>
                <div className="auth-switch">
                    Already registered? <Link to="/login" className="link-button">Login</Link>
                </div>
            </form>

            {/* Google Sign-In Button */}
            <div id="googleSignInButton"></div>
        </div>
    );
}

export default Register;
