import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

export const ViewTask = ({ item, answer, workflowId }) => {
    const [currentValue, setCurrentValue] = useState(item.fieldValue || answer || '');

    switch (item.taskField.fieldTypeID) {
        case 'text':
        case 'number':
        case 'password':
            return (
                <>
                    <h5 htmlFor={item.id}>{item.taskField.fieldName}</h5>
                    <Form.Control
                        type={item.taskField.fieldTypeID}
                        id={item.id}
                        value={currentValue}
                        readOnly
                        placeholder={`Enter ${item.taskField.fieldName}`}
                    />
                </>
            );
        case 'information':
            return <p id={item.id}>{item.taskField.fieldName}</p>;
        default:
            return <p>Unsupported field type: {item.taskField.fieldTypeID}</p>;
    }
};

export default ViewTask;
