import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../index.css';
import './WorkflowList.css';
import { WorkflowsData } from "../WorkflowData";
import parse from 'html-react-parser'; // Import the parse function
import config from '../config';



function WorkflowList() {
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      console.error('No auth token available');
      setError('Authentication required.');
      setLoading(false);
      return;
    }

    axios.get(`${config.apiUrl}/user-workflows/`, {
      headers: {
        'Authorization': `Token ${authToken}`
      }
    })
      .then(response => {
        const workflowsWithCounts = response.data.map(userWorkflow => {
          const doneCount = userWorkflow.user_tasks.reduce((acc, task) => acc + (task.status === 'Done' ? 1 : 0), 0);
          const totalCount = userWorkflow.user_tasks.length;
          const donePercentage = totalCount > 0 ? Math.round((doneCount / totalCount) * 100) : 0;
          return {
            id: userWorkflow.id,
            name: userWorkflow.workflow.name,
            description: userWorkflow.workflow.description,
            donePercentage
          };
        });
        setWorkflows(workflowsWithCounts);
        setLoading(false);
      })
      .catch(error => {
        console.error('Detailed Error fetching workflows:', error);
        setError('Failed to fetch workflows. Please try again.');
        setLoading(false);
      });
  }, []);

  function handleDelete(workflowId) {
    if (window.confirm("Are you sure you want to delete this workflow?")) {
      axios.delete(`${config.apiUrl}/user-workflows/${workflowId}`, {
        headers: {
          'Authorization': `Token ${localStorage.getItem('authToken')}`
        }
      })
        .then(() => {
          setWorkflows(workflows.filter(workflow => workflow.id !== workflowId));
        })
        .catch(error => {
          console.error('Failed to delete the workflow:', error);
        });
    }
  }

  const filteredWorkflows = workflows.filter(workflow =>
    workflow.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    workflow.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
    workflow.id.toString().includes(searchQuery)
  );

  if (loading) return <div>Loading workflows...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="workflow-list-container">
      <input
        type="text"
        placeholder="Search workflows..."
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        className="search-bar"
      />
      <div className="workflow-list">
        {filteredWorkflows.length > 0 ? filteredWorkflows.map(workflow => (
          <Link key={workflow.id} to={`/user-dashboard/workflows/${workflow.id}`} className="workflow-card-link">
            <div className="workflow-card">
              <h2>{workflow.name}</h2>
              <p className="workflow-description">{parse(workflow.description)}</p>
              <div className="workflow-controls">
                <button onClick={(e) => { e.stopPropagation(); handleDelete(workflow.id); }} className="delete-button">
                  ❌
                </button>
                <div className="workflow-count">{workflow.donePercentage}%</div>
              </div>
            </div>
          </Link>
        )) : <div>No workflows found.</div>}
      </div>
    </div>
  );
}

export default WorkflowList;
