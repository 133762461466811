import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from './UserContext';
import './Login.css';
import config from '../config';
import { initializeGoogleSignIn, handleCredentialResponse } from '../services/googleAuthService';

function Login() {
    const [emailOrUsername, setEmailOrUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { setUser } = useContext(UserContext);

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${config.apiUrl}/auth/login/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email_or_username: emailOrUsername, password })
            });
            if (!response.ok) throw new Error('Login failed: ' + response.statusText);
            const data = await response.json();
            localStorage.setItem('authToken', data.key); // Save the token to localStorage
            setUser({ username: emailOrUsername });
            navigate('/');  // Navigate to homepage or dashboard after login
        } catch (error) {
            console.error('Login error:', error);
            alert('Failed to log in: ' + error.message);
        }
    };

    useEffect(() => {
        const callback = async (response) => {
            const result = await handleCredentialResponse(response.credential, config.apiUrl);
            if (result.success) {
                localStorage.setItem('authToken', result.key); // Save the token to localStorage
                setUser({ username: result.username });
                if (result.created) {
                    navigate('/profile'); // Redirect to profile setup
                } else{
                    navigate('/'); // Redirect after login
                }
            } else {
                alert('Google Sign-In failed: ' + result.message);
            }
        };

        initializeGoogleSignIn(config.googleClientId, callback);


    }, [navigate]);

    return (
        <div className="auth-container">
            <form onSubmit={handleLogin} className="auth-form">
                <h2>Login</h2>
                <input
                    type="text"
                    placeholder="Username or Email"
                    value={emailOrUsername}
                    onChange={e => setEmailOrUsername(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <button type="submit">Login</button>
                <div className="auth-switch">
                    Not registered yet? <Link to="/register" className="link-button">Sign Up</Link>
                </div>
            </form>

            {/* Google Sign-In Button */}
            <div id="googleSignInButton"></div> 
        </div>
    );
}

export default Login;
