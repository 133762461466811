import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../index.css';
import './BrowseWorkflows.css';
import parse from 'html-react-parser'; // Import the parse function
import config from '../config';


function WorkflowList() {
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [addingWorkflowId, setAddingWorkflowId] = useState(null);
  const [addWorkflowError, setAddWorkflowError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      console.error('No auth token available');
      setError('Authentication required.');
      setLoading(false);
      return;
    }

    axios.get(`${config.apiUrl}/workflows/`, {
      headers: { 'Authorization': `Token ${authToken}` }
    })


    .then(response => {
      setWorkflows(response.data);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error fetching workflows:', error);
      setError('Failed to fetch workflows. Please try again.');
      setLoading(false);
    });
  }, []);

  const handleAddWorkflow = (workflowId) => {
    console.log('Adding workflow:', workflowId);
    setAddingWorkflowId(workflowId);
    setAddWorkflowError('');

    axios.post(`${config.apiUrl}/user-workflows/`, 
      { workflow_id: workflowId, status: 'active' },  // Ensure correct field name
      { headers: { Authorization: `Token ${localStorage.getItem('authToken')}` } }
    )
    .then(response => {
      alert('Workflow added successfully');
      setAddingWorkflowId(null);
      // Update UI or Redirect as necessary
    })
    .catch(error => {
      console.error('Failed to add workflow:', error.response.data);
      setAddWorkflowError(`Failed to add workflow: ${error.response.data}`);
      setAddingWorkflowId(null);
    });
  };

  const filteredWorkflows = workflows.filter(workflow =>
    workflow.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    workflow.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
    workflow.id.toString().includes(searchQuery)
  );

  if (loading) return <div>Loading workflows...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="workflow-list-container">
      <input
        type="text"
        placeholder="Search workflows..."
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        className="search-bar"
      />
      <div className="workflow-list">
        {addWorkflowError && <div className="error-message">{addWorkflowError}</div>}
        {filteredWorkflows.length > 0 ? filteredWorkflows.map(workflow => (
          <div key={workflow.id} className="workflow-card">
            <div className="workflow-info">
              <h2>{workflow.name}</h2>
              <p className="workflow-description">{parse(workflow.description)}</p>
            </div>
            <div className="user-count">
              <i className="fas fa-users"></i> {workflow.user_count}
            </div>
            <button 
              className="add-workflow-button" 
              onClick={() => handleAddWorkflow(workflow.id)} 
              disabled={addingWorkflowId === workflow.id}
            >
              {addingWorkflowId === workflow.id ? 'Adding...' : 'Add Workflow'}
            </button> 
            <Link to={`/browse-workflows/workflows/${workflow.id}`}>View Workflow Details</Link>
          </div>
        )) : <div>No workflows found.</div>}
      </div>
    </div>
  );
}

export default WorkflowList;
