import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './WorkflowTasks.css';

const WorkflowTasks = ({ tasks, workflowId }) => {
    const navigate = useNavigate();

    const getColor = (status) => {
        if (status === "Done") return '#28a745'; // Green for completed tasks
        return '#007BFF'; // Blue for uncompleted tasks
    };

    const handleStepClick = (index) => {
        //display the tasks and workflowId
        
        console.log('Fetching tasks:', tasks);
        console.log('Fetching workflowId:', workflowId);
        console.log('Task clicked:', index);
        console.log('workflowId:', workflowId);
        console.log('taskId:', tasks[index].id);
        
        // Navigate to the specific step detail page
        navigate(`/user-dashboard/workflows/${workflowId}/step/${tasks[index].id}`);
    };

    return (
        <div>
            <table className="progress-bar-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Step</th>
                        <th style={{ width: "300px" }}>Progress</th>
                    </tr>
                </thead>
                <tbody>
                    {tasks.map((task, index) => (
                        <tr key={task.id}>
                            <td>{index + 1}</td>
                            <td> 
                                <button onClick={() => handleStepClick(index)}
                                    style={{
                                        background: 'none',
                                        color: 'inherit',
                                        border: 'none',
                                        padding: '0',
                                        font: 'inherit',
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        color: getColor(task.status),
                                        outline: 'inherit'
                                    }}
                                >
                                    {task.name}
                                </button>
                            </td>
                            <td>
                                <div className="progress">
                                    <div 
                                        className="progress-bar" 
                                        style={{ 
                                            width: `${task.status === "Done" ? 100 : 10}%`,
                                            backgroundColor: getColor(task.status)
                                        }}
                                    >
                                        {task.status === "Done" ? 100 : 10}%
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default WorkflowTasks;
