import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useLocation } from 'react-router-dom';
import { UserProvider, UserContext } from './login/UserContext';
import WorkflowList from "./pages/WorkflowList";
import WorkflowDetail from "./pages/WorkflowDetail";
import Login from "./login/Login";
import Register from "./login/Register";
import AddWorkflow from "./pages/AddWorkflow" // Import the new component
import BrowseWorkflows from "./pages/BrowseWorkflows" // Import the new component
import './App.css';
import PreviewWorkflowDetails from './pages/PreviewWorkflowDetails';
import EditProfile from './login/EditProfile';
import WorkflowStepDetail from './pages/WorkflowStepDetail';
import AdminDashboard from './pages/AdminDashboard';
import EditWorkflow from "./pages/EditWorkflow";
import ViewWorkflow from "./pages/ViewWorkflow";
import ViewUserTasks from './pages/ViewUserTasks';  // Import the ViewUserTasks component

function App() {
    return (
        <UserProvider>
            <Router>
                <Navigation />
                <div className="App">
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/" element={<Navigate to="/user-dashboard" />} />
                        <Route path="/profile" element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />

                        <Route path="/user-dashboard" element={<ProtectedRoute><WorkflowList /></ProtectedRoute>} />
                        <Route path="/user-dashboard/workflows/:id" element={<ProtectedRoute><WorkflowDetail /></ProtectedRoute>} />
                        <Route path="/user-dashboard/workflows/:id/step/:stepId" element={<ProtectedRoute><WorkflowStepDetail /></ProtectedRoute>} />

                        <Route path="/admin-dashboard" element={<ProtectedRoute><AdminDashboard /></ProtectedRoute>} />
                        <Route path="/admin-dashboard/create-workflow" element={<ProtectedRoute><AddWorkflow /></ProtectedRoute>} />
                        <Route path="/admin-dashboard/edit-workflow/workflows/:id" element={<ProtectedRoute><EditWorkflow /></ProtectedRoute>} />
                        <Route path="/admin-dashboard/view/workflows/:id" element={<ProtectedRoute><ViewWorkflow /></ProtectedRoute>} />
                        <Route path="/admin-dashboard/view/workflows/:workflowId/users/:userId" element={<ProtectedRoute><ViewUserTasks /></ProtectedRoute>} />

                        <Route path="/browse-workflows" element={<ProtectedRoute><BrowseWorkflows /></ProtectedRoute>} />
                        <Route path="/browse-workflows/workflows/:id" element={<PreviewWorkflowDetails />} />


                        <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                </div>
            </Router>
        </UserProvider>
    );
}

function Navigation() {
    const { user, logout } = useContext(UserContext);
    const location = useLocation();

    // Determine if the navigation should be shown
    const showNav = location.pathname !== "/login" && location.pathname !== "/register";

    return (
        showNav && (
            <nav>
                <h1>Workflow Manager</h1>
                <Link to="/">User-Dashboard</Link>
                {user ? (
                    <>
                        <Link to="/browse-workflows">Browse Workflows</Link>
                        <Link to="/admin-dashboard">Admin-Dashboard</Link>
                        <button onClick={logout}>Logout</button>
                        <a href="/profile"><i className="fas fa-user-edit"></i></a>

                    </>
                ) : (
                    <>
                        <Link to="/login">Login</Link>
                        <Link to="/register">Register</Link>
                    </>
                )}
            </nav>
        )
    );
}

function ProtectedRoute({ children }) {
    const { user, loading } = useContext(UserContext);

    if (loading) {
        return <div>Loading...</div>; // Or any other loading indicator
    }

    if (!user) {
        return <Navigate to="/login" replace />;
    }

    return children;
}
export default App;


