import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Container, Row, Col } from 'react-bootstrap';
import WorkflowTasks from '../components/WorkflowTasks'; // Assuming you update this component to handle click events
import { MultiStepForm } from '../components/MultiStepForm'; // Adjust the import path as necessary
import { MultiStepTask } from '../components/MultiStepTask'; // Adjust the import path as necessary


const ViewUserTasks = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { userWorkflow } = location.state; // Access the passed userWorkflow from state

    const userTasks = userWorkflow.user_tasks;
    const workflowTasks = userWorkflow.workflow.tasks;
    const workflowId = userWorkflow.workflow.id;


    const [selectedTask, setSelectedTask] = useState(null); // To keep track of the selected task
    const [stepId, setStepId] = useState(null);

    const getColor = (status) => {
        if (status === "Done") return '#28a745'; // Green for completed tasks
        return '#007BFF'; // Blue for uncompleted tasks
    };

    const handleStepClick = (index) => {
        // Set the selected task when a step is clicked
        setStepId(userTasks[index].id);
        setSelectedTask(userTasks[index]);
    };

    const handlePageUpdate = (step, data) => {
        console.log(`Data updated at step ${step}:`, data);
        // You can add more functionality here, such as making an API call to update the server
    };

    console.log('User Workflow:', userWorkflow);
    console.log('User Tasks:', userTasks);
    console.log('Workflow Tasks:', workflowTasks);

    return (
        <Container>
            <h2>Tasks for {userWorkflow.user_name}:</h2>
            {!selectedTask && (

                <div>
                    <table className="progress-bar-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Step</th>
                                <th style={{ width: "300px" }}>Progress</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userTasks.map((task, index) => (
                                <tr key={task.id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <button onClick={() => handleStepClick(index)}
                                            style={{
                                                background: 'none',
                                                color: 'inherit',
                                                border: 'none',
                                                padding: '0',
                                                font: 'inherit',
                                                cursor: 'pointer',
                                                textDecoration: 'underline',
                                                color: getColor(task.status),
                                                outline: 'inherit'
                                            }}
                                        >
                                            {task.name}
                                        </button>
                                    </td>
                                    <td>
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                style={{
                                                    width: `${task.status === "Done" ? 100 : 10}%`,
                                                    backgroundColor: getColor(task.status)
                                                }}
                                            >
                                                {task.status === "Done" ? 100 : 10}%
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            {selectedTask && (
                <Container className="mt-5">
                    <Row className="mb-3">
                        <Col>
                            <h2>{selectedTask.name}</h2>
                            <p>{selectedTask.description}</p>
                        </Col>
                    </Row>
                    <Card>
                        <Card.Body>
                            <MultiStepTask list={selectedTask} step={parseInt(stepId, 10) - 1} onPageUpdate={handlePageUpdate} workflowId={workflowId} userTask={selectedTask} />
                        </Card.Body>
                    </Card>
                    <Row className="mt-3">
                        <Col>
                            <button className="btn btn-primary" onClick={() => setSelectedTask(null)}>Back to user tasks</button>
                        </Col>
                    </Row>
                </Container>
            )}

        </Container>
    );

};

export default ViewUserTasks;
