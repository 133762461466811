import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, Container, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import './WorkflowList.css';
import config from '../config';

function EditWorkflow() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [workflow, setWorkflow] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [newFieldNames, setNewFieldNames] = useState({});

    useEffect(() => {
        const fetchWorkflow = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                const response = await axios.get(`${config.apiUrl}/workflows/${id}`, {
                    headers: {
                        'Authorization': `Token ${authToken}`
                    }
                });
                console.log("Fetched workflow data:", response.data); // Log the fetched data
                setWorkflow(response.data);  // Ensure data structure matches the response
                setLoading(false);
            } catch (error) {
                const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
                console.error('Failed to fetch workflow details:', errorMessage);
                setError('Failed to fetch workflow details: ' + errorMessage);
                setLoading(false);
            }
        };

        fetchWorkflow();
    }, [id]);

    const handleTaskChange = (e, stepIndex, fieldIndex) => {
        const newTasks = workflow.tasks.slice();
        newTasks[stepIndex].task_fields[fieldIndex].fieldValue = e.target.value;
        setWorkflow({ ...workflow, tasks: newTasks });
    };

    const handleFieldNameChange = (value, stepIndex) => {
        setNewFieldNames({ ...newFieldNames, [stepIndex]: value });
    };

    const addField = (stepIndex) => {
        const fieldName = newFieldNames[stepIndex];
        if (!fieldName) {
            alert("Please enter a field name.");
            return;
        }
        const newField = {
            fieldName: fieldName,
            fieldTypeID: 'text',
            required: false,
            index: workflow.tasks[stepIndex].task_fields.length
        };
        const newTasks = [...workflow.tasks];
        newTasks[stepIndex].task_fields.push(newField);
        setWorkflow({ ...workflow, tasks: newTasks });

        setNewFieldNames({ ...newFieldNames, [stepIndex]: "" });
    };

    const deleteField = (stepIndex, fieldIndex) => {
        const newTasks = [...workflow.tasks];
        newTasks[stepIndex].task_fields.splice(fieldIndex, 1);
        setWorkflow({ ...workflow, tasks: newTasks });
    };

    const deleteStep = (stepIndex) => {
        const newTasks = [...workflow.tasks];
        newTasks.splice(stepIndex, 1);
        setWorkflow({ ...workflow, tasks: newTasks });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
            alert("You are not authenticated. Please login.");
            return;
        }

        try {
            console.log("Submitting workflow:", JSON.stringify(workflow, null, 2));
            await axios.put(`${config.apiUrl}/workflows/${workflow.id}/`, workflow, {
                headers: {
                    'Authorization': `Token ${authToken}`,
                }
            });
            alert('Workflow updated successfully');
        } catch (error) {
            const errorMessage = error.response?.data?.message || "An unknown error occurred. Please check your input and try again.";
            console.error('Failed to update workflow:', errorMessage);
            alert('Failed to update workflow: ' + errorMessage);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    if (!workflow) return <p>No workflow data available</p>;

    return (
        <Container className="mt-5">
        <Row className="m-5">
            <Col>
                <h2>Edit Workflow: {workflow.name}</h2>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="workflowName">
                        <Form.Label>Workflow Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={workflow.name}
                            onChange={(e) => setWorkflow({ ...workflow, name: e.target.value })}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="workflowDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={workflow.description}
                            onChange={(e) => setWorkflow({ ...workflow, description: e.target.value })}
                            required
                        />
                    </Form.Group>
                    {workflow.tasks && workflow.tasks.map((task, stepIndex) => (
                        <Card key={stepIndex} className="mt-3">
                            <Card.Body>
                                <Card.Title className="d-flex justify-content-between align-items-center">
                                    Step {stepIndex + 1}
                                    <button  onClick={() => deleteStep(stepIndex)} className="delete-button">
                                        ❌
                                    </button>
                                </Card.Title>
                                <Form.Group controlId={`taskName${stepIndex}`}>
                                    <Form.Label>Task Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={task.name}
                                        onChange={(e) => {
                                            const newTasks = [...workflow.tasks];
                                            newTasks[stepIndex].name = e.target.value;
                                            setWorkflow({ ...workflow, tasks: newTasks });
                                        }}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId={`taskDescription${stepIndex}`}>
                                    <Form.Label>Task Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={task.description}
                                        onChange={(e) => {
                                            const newTasks = [...workflow.tasks];
                                            newTasks[stepIndex].description = e.target.value;
                                            setWorkflow({ ...workflow, tasks: newTasks });
                                        }}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId={`newFieldName${stepIndex}`}>
                                    <Form.Label>New Field Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter new field name"
                                        value={newFieldNames[stepIndex] || ''}
                                        onChange={(e) => handleFieldNameChange(e.target.value, stepIndex)}
                                    />
                                    <Button type="button" onClick={() => addField(stepIndex)} className="mt-2">Add Field</Button>
                                </Form.Group>
                                {task.task_fields && task.task_fields.map((field, fieldIndex) => (
                                    <Form.Group key={fieldIndex} controlId={`field${stepIndex}${fieldIndex}`} className="mt-2">
                                        <Form.Label>Field Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={field.fieldName}
                                            onChange={(e) => {
                                                const newTasks = [...workflow.tasks];
                                                newTasks[stepIndex].task_fields[fieldIndex].fieldName = e.target.value;
                                                setWorkflow({ ...workflow, tasks: newTasks });
                                            }}
                                            required
                                        />
                                        <Form.Label>Field Type</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={field.fieldTypeID}
                                            onChange={(e) => {
                                                const newTasks = [...workflow.tasks];
                                                newTasks[stepIndex].task_fields[fieldIndex].fieldTypeID = e.target.value;
                                                setWorkflow({ ...workflow, tasks: newTasks });
                                            }}
                                            required
                                        >
                                            <option value="text">Text</option>
                                            <option value="number">Number</option>
                                            <option value="date">Date</option>
                                            <option value="information">Information</option>
                                        </Form.Control>
                                        <Button variant="danger" onClick={() => deleteField(stepIndex, fieldIndex)} className="mt-2">Delete Field</Button>
                                    </Form.Group>
                                ))}
                            </Card.Body>
                        </Card>
                    ))}
                    <Button type="submit" className="mt-4">Update Workflow</Button>
                </Form>
            </Col>
        </Row>
    </Container>
    );
}

export default EditWorkflow;
