import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true); // Add a loading state

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            // Here you can validate the token if required
            setUser({ authToken }); // Set user directly if no backend validation
            setLoading(false); // Set loading to false after user is set
        } else {
            setLoading(false); // Set loading to false if no token
        }
    }, []);

    const logout = () => {
        localStorage.removeItem('authToken');
        setUser(null);
    };

    return (
        <UserContext.Provider value={{ user, setUser, logout, loading }}>
            {children}
        </UserContext.Provider>
    );
};
