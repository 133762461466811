import React, { useState, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import config from '../config';


export const FormItem = ({ item, onChange, answer, workflowId }) => {
    const [currentValue, setCurrentValue] = useState(item.fieldValue || answer || '')

    // Function to handle input changes and debounce save
    const handleChange = (e) => {
        const newValue = e.target.value;
        setCurrentValue(newValue);
        onChange(item.id, newValue); // This callback might need item ID and the new value
        debouncedSave(item.id, newValue);
    };

    // Debounce function to prevent excessive API calls
    const debounce = (func, delay) => {
        let inDebounce;
        return function (...args) {
            clearTimeout(inDebounce);
            inDebounce = setTimeout(() => func(...args), delay);
        };
    };

    // Function to save data to the backend
    const saveData = useCallback((fieldId, value) => {
        const authToken = localStorage.getItem('authToken'); // Get the auth token from local storage
        console.log(`Saving data for field ${item.taskField.fieldName} with ID ${fieldId}: ${value}`);
        console.log('Workflow ID:', workflowId);
        axios.patch(`${config.apiUrl}/user-task-fields/${fieldId}/`, {
            fieldValue: value
        }, {
            headers: {
                'Authorization': `Token ${authToken}`  // Include the authorization header
            }
        })
            .then(response => console.log('Save successful', response))
            .catch(error => console.error('Save failed', error));
    }, [item.taskField.fieldName, workflowId]);

    // Wrap saveData to debounce it
    const debouncedSave = useCallback(debounce(saveData, 10000), [saveData]);

    switch (item.taskField.fieldTypeID) {
        case 'text':
        case 'number':
        case 'password':
            return (
                <>
                    <h5 htmlFor={item.id}>{item.taskField.fieldName}</h5>
                    <Form.Control
                        type={item.taskField.fieldTypeID}
                        id={item.id}
                        value={currentValue}
                        onChange={handleChange}
                        placeholder={`Enter ${item.taskField.fieldName}`}
                    />
                </>
            );
        case 'information':
            return <p id={item.id}>{item.taskField.fieldName}</p>;
        default:
            return <p>Unsupported field type: {item.taskField.fieldTypeID}</p>;
    }
};

export default FormItem;
