import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import './WorkflowList.css';
import { useNavigate } from 'react-router-dom';
import config from '../config';

const ViewWorkflow = () => {
    const { id } = useParams();
    const location = useLocation();
    const [workflowName] = useState(location.state?.workflowName || '');
    const [workflowUsers, setWorkflowUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        console.log('Fetching workflow details for workflow ID:', id);
        console.log('Fetching workflow details for workflow Name:', workflowName);
        const fetchWorkflowUsers = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                const response = await axios.get(`${config.apiUrl}/workflows/${id}/users`, {
                    headers: {
                        'Authorization': `Token ${authToken}`
                    }
                });
                console.log("Fetched workflow data:", response.data); // Log the fetched data
                setWorkflowUsers(response.data);  // Ensure data structure matches the response
                setLoading(false);
            } catch (error) {
                const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
                console.error('Failed to fetch workflow details:', errorMessage);
                setError('Failed to fetch workflow details: ' + errorMessage);
                setLoading(false);
            }
        };

        fetchWorkflowUsers();
    }, [id, workflowName]); // Include workflowName as a dependency

    const navigate = useNavigate();

    const handleViewTasks = (workflowId, userId, userWorkflow) => {
        navigate(`/admin-dashboard/view/workflows/${workflowId}/users/${userId}`, {
            state: { userWorkflow }  // Pass userWorkflow as state
        });
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    const filteredWorkflows = workflowUsers.filter(workflowUser =>
        workflowUser.user_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        workflowUser.user_id.toString().includes(searchQuery) ||
        workflowUser.status.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Container>
            <h1></h1>
            <input
                type="text"
                placeholder="Search users..."
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                className="search-bar"
            />
            <h2>{workflowName} Workflow</h2>
            {filteredWorkflows.length > 0 ? (
                <Row>
                    {filteredWorkflows.map(userWorkflow => (
                        <Col sm={12} md={6} lg={4} key={userWorkflow.id} className="mb-4">
                            <Card className="shadow-sm h-100">
                                <Card.Body>
                                    <Card.Title className="text-center">
                                        {userWorkflow.user_name}
                                    </Card.Title>
                                    <Card.Text className="text-center">
                                        <strong>Status:</strong> {userWorkflow.status}
                                        <div className="workflow-con">
                                            <div className="workflow-count" >0%</div>
                                        </div>
                                    </Card.Text>
                                    <div className="text-center mt-4">
                                        <Button
                                            variant="primary"
                                            onClick={() => handleViewTasks(userWorkflow.workflow.id, userWorkflow.user_id, userWorkflow)}
                                        >
                                            View Tasks
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>

            ) : (
                <div>No users found.</div>
            )}

        </Container>

    );
};

export default ViewWorkflow;
